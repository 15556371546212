<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Normatīvie akti"
            header-tag="header"
            footer-tag="footer"
            footer=" "
            v-if="data"
        >
          <b-button variant="success" class="float-right" :to="{ name: 'RegulationsCreate' }" v-can="'regulations.manage'">Pievienot</b-button>

          <div class="group-title">
            <b-button v-b-toggle.collapse-1 squared variant="primary">Slēpt/Rādīt</b-button>
            Dokumenti:
          </div>

          <b-collapse id="collapse-1" class="mt-2">
            <b-table striped hover :fields="fields" :items="data.documents">

              <template #cell(updated_at)="row">
                <span> {{ dateFormat(row.item.updated_at, "dd.mm.yyyy") }} </span>
              </template>

              <template #cell(actions)="row">
                <b-button  size="sm" variant="success" target="_blank" :href="media+'/na/'+row.item.file" class="mr-1">
                  Skatīt
                </b-button>

                <b-button v-can="'regulations.manage'" size="sm" variant="warning" v-if="User.isAdmin" @click="$router.push({ name: 'RegulationsView', params: {id: row.item.id} })" class="mr-1">
                  Labot/Skatīt
                </b-button>

                <b-button v-can="'regulations.manage'" size="sm" variant="danger" v-b-modal.modal-sm @click="archiveItem(row.item.id)">
                  Dzēst
                </b-button>
              </template>

            </b-table>
          </b-collapse>

          <div class="group-title">
            <b-button v-b-toggle.collapse-2 squared variant="primary">Slēpt/Rādīt</b-button>
            Instrukcijas:
          </div>

          <b-collapse id="collapse-2" class="mt-2">
            <b-table striped hover :fields="fields" :items="data.instructions">

              <template #cell(created_at)="row">
                <span> {{ dateFormat(row.item.created_at, "dd.mm.yyyy") }} </span>
              </template>

              <template #cell(updated_at)="row">
                <span> {{ dateFormat(row.item.updated_at, "dd.mm.yyyy") }} </span>
              </template>

              <template #cell(actions)="row">
                <b-button size="sm" variant="success" target="_blank" :href="media+'/na/'+row.item.file" class="mr-1">
                  Skatīt
                </b-button>

                <b-button v-can="'regulations.manage'" size="sm" variant="warning" @click="$router.push({ name: 'RegulationsView', params: {id: row.item.id} })" class="mr-1">
                  Labot/Skatīt
                </b-button>

                <b-button v-can="'regulations.manage'" size="sm" variant="danger" v-b-modal.modal-sm @click="archiveItem(row.item.id)">
                  Dzēst
                </b-button>
              </template>

            </b-table>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";

const dateFormat = require('dateformat');

export default {
  data() {
    return {
      data: null,
      dateFormat: dateFormat,
      media: process.env.VUE_APP_MEDIA_URL,
      fields: [
        {
          key: 'name',
          label: 'Nosaukums',
          sortable: true,
          tdClass: 'nameColumn'
        },
        {
          key: 'updated_at',
          label: 'Aktualizēts',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Darbības',
        }
      ],
      items: [
        {
          name: 40,
          created_at: 'Dickerson',
          updated_at: 'Macdonald'
        },
        {
          name: 40,
          created_at: 'Dickerson',
          updated_at: 'Macdonald'
        },
      ]
    }
  },
  computed: {
    ...mapGetters ({
      User: "Auth/user",
    }),
  },
  methods: {
    archiveItem(id) {
      axios.delete('/regulations/'+id).then(response => {

        this.getData();

        this.created = 'success';
      }).catch(e => {
        this.created = 'error';
      })

      this.dismissCountDown = 5;
    },
    getData() {
      axios.get('/regulations').then(response => {
        this.data = response.data.data;
        console.log(this.data);
      });
    },
  },
  mounted() {
    this.getData();
  },
}
</script>

<style lang="scss">
.nameColumn {
  max-width: 20vw;
}
</style>